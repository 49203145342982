:root {
  --color-purple: hsl(244, 58%, 66%);
  --color-purple-dark: hsl(244, 58%, 51%);
  --color-purple-grey: hsl(244, 18%, 66%);
  --color-orange: hsl(33, 80%, 62%);
  --color-orange-translucent: hsla(33, 80%, 62%, 0.6);
  --color-orange-grey: hsl(33, 20%, 62%);
  --color-aqua: hsl(177, 56%, 83%);
  --color-black: hsl(210, 13%, 9%);
  --color-white: hsl(180, 100%, 100%);
  --color-twitter: hsl(203, 89%, 53%);
  --color-discord: hsl(227, 58%, 65%);
  --color-opensea: hsl(210, 77%, 51%);
}
