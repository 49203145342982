.gradient-orange {
  background: #eca651;
  background: linear-gradient(to top, #ffd503, #ffe203, #f8b200);
}

.gradient-zimmy-purple {
  background: #baa4ec;
  background: linear-gradient(to bottom, #fcfcfc, #dad0f3 50%, #8555ec);
}

.gradient-rose {
  background: #f4c4f3;
  background: radial-gradient(#fcfcfc, #f4c4f3 60%, #fc67fa);
}

.gradient-ash {
  background: #606c88;
  background: linear-gradient(to right, #606c88, #3f4c6b);
}

.gradient-blue {
  background: #00c6ff;
  background: linear-gradient(to right, #00c6ff, #0072ff);
}

.gradient-earthly {
  background: #649173;
  background: linear-gradient(to top, #dbd5a4, #649173);
}

.gradient-margo {
  background: #ffefba;
  background: radial-gradient(#ffffff 40%, #ffefba);
}

.gradient-purple {
  background: #9d50bb;
  background: linear-gradient(to right, #9d50bb, #6e48aa);
}

.gradient-meadow {
  background: #215f00;
  background: linear-gradient(to top, #e4e4d9, #215f00);
}

.gradient-mist {
  background: #83a4d4;
  background: linear-gradient(to right, #83a4d4, #b6fbff);
}

.gradient-shore {
  background: #70e1f5;
  background: linear-gradient(to top, #70e1f5, #b6fbff, #ffd194);
}

.gradient-egg {
  background: #a770ef;
  background: linear-gradient(to bottom, #70e1f5, #b6fbff, #a770ef);
}
