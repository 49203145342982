@import './_typography.css';
@import './_reset.css';
@import './_colors.css';
@import './_gradients.css';

body {
  margin: 0;
  font-family: var(--font-body);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
}

#root {
  width: 100vw;
  overflow-x: hidden;
}
