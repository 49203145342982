@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Nunito+Sans&family=Roboto+Slab&display=swap');
:root {
  --font-heading: 'Roboto Slab', serif;
  --font-body: 'Nunito Sans', sans-serif;
  --font-alt: 'Bubblegum Sans', sans-serif;

  --size-0: 3rem;
  --size-1: 2.5rem;
  --size-2: 2rem;
  --size-3: 1.5rem;
  --size-4: 1rem;
  --size-5: 0.5rem;
}

html {
  font-size: 10px;
}

@media (min-width: 400px) and (max-width: 800px) {
  html {
    font-size: calc(10px + (16 - 10) * ((100vw - 400px) / (800 - 400)));
  }
}
@media (min-width: 800px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 800px) and (max-height: 660px) {
  html {
    font-size: 2vh;
  }
}
